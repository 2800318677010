.side-form{
  position: fixed;
 #{$right}:0;
  width: 230px;
  height: 100%;
  z-index: 20000;
  background-color: $color-white;
  border: solid 1px #d6d6d6;
  box-shadow: 0 0 -20px rgba(0,0,0,.4);
  transition: .3s;
  @include respond-to('small'){
  overflow:hidden;
  }
  @at-root #{&}_hidden{
      transition: .3s;
      transform: translateX(150%);
  }

  @at-root #{&}__logo{
    background:#f8f8f8;
    height:85px;
    display:inline-block;
    border-top: solid 1px #ffffff;
    border-bottom: solid 1px #d6d6d6;
    >img{
        max-width:95%;
    }
  }

  @at-root #{&}__open-btn {
  margin-top: 6px;
  margin-#{$right}:20px;
  float:$left;
  cursor:pointer;
}
  @at-root #{&}__reveal-btn{
    position:fixed;
    top:205px;
    height:80px;
    width:280px;
    background-color: #fff;
    border-radius:  40px 0  0 40px;
    box-shadow: 0 0 20px rgba(0,0,0,.1);
    z-index: 2;
    transition: .3s;
    $rb: &;

      @include respond-to('medium'){
            top:32vh;
            right:0;
        .side-form__hidden & {
           width:120px;
           &_text {
             display:table;
             & > img{
               position:absolute;
               #{$left}:0;
              }
             & >span{
               text-align: center;
               width:40%;
                position:relative;
                display:table-cell;
                vertical-align: middle;
                font-size:21px;
               margin-top:-10px;
                float:$right;
               }
             }
      }
    }
    @include respond-to('small'){
          top:unset;
          bottom: 15px;
          height: 40px;
      .side-form__hidden & {
         &_text {

           & > img{
             max-height: 80%;
             top: 0px;
            }
           & >span{
              font-size:14px;
             }
           }
    }
  }


    &_text{
      font-family:'Open Sans Hebrew', sans-serif;
      font-size: 39px;
      font-weight: bold;
      color:$brand-color;
      float:$left;

      display:block;
      padding:20px 15px;


      /* reveal-btn_text_open */
      &_open{
        line-height: 1;
        display: block;
        transform:translateX(0%);
        padding:20px 15px;
        margin:0px;
        font-family:'Assistant', sans-serif;
        &_pre{
          font-size: 18px;
          font-weight: bold;
          color:#777777;
          }
        &_title{
        font-size: 24px;
        font-weight: bold;
        color:$main-color;
        padding-#{$left}:25px;
        }

      >img{
        margin-top: 8px;
        margin-#{$left}:0px;
        float:$left;
        cursor:pointer;
      }
    }
    }

    &:after{
      content: '';
      position: absolute;
      width: 30px;
      height: 100%;
      #{$right}: -30px;
      top: 0;
      background-color: #fff;
    } //after - shadow-cover

    >div{
      opacity: 1;
      height: 0;
      transition: .3s;
      padding-top: 11px;
      padding-#{$right}: 10px;
      transition: .3s;
    }

    >img{
      display:table-cell;
      vertical-align: middle;
      opacity: 1;
      transition: .3s;
      float:$left;
    }
    @include respond-to('medium'){
    left:0;
    }
  } //__reveal-btn




  @at-root #{&}__contacts-icn{
    margin-top:10px;
       margin-right:10px;

    @include respond-to('small'){
    margin-right:0px;
    }

  } //__contacts-icn
  @at-root #{&}__details{
    @include respond-to('medium'){
    padding:10px;
    display:inline-block;
    }
    @include respond-to('small'){
    margin:0;
    display:inline;
    }

   } //__details
  @at-root #{&}__phone{
        padding: 3px;

      }
  @at-root #{&}__email{
        padding: 9px 0px;
        background-color: #f8f8f8;
        margin-bottom:90px;
      }
  @at-root #{&}__phone, #{&}__email{
        border-bottom: solid 1px #d6d6d6;
    @include respond-to('small'){
    padding:10px;
    }

  }
  @at-root #{&}__phone-text , #{&}__email-text{
    font-size: 18px;
    font-weight: bold;
    color:#777777;
    white-space: nowrap;
  } //__phone

    @at-root #{&}__phone-num{
      font-size: 24px;
      font-weight: bold;
      color:$main-color;
          white-space: nowrap;
    } //__phone

    @at-root #{&}__email-add{
      font-size: 16px;
      font-weight: bolder;
      color:$main-color;
          white-space: nowrap;
    } //__phone

  @at-root #{&}__form{
    padding: 10px;


    input, select, textarea{
      width: 100%;
      margin-bottom: 10px;
      border: solid 1px #d6d6d6;
      border-radius: 4px;
      background-color: #f6f6f6;
      box-shadow: 1px 3px 5px rgba(0,0,0,.1) inset;
      padding: 5px 10px;
    } //input select textarea

    input, select{
      height: 40px;
    } //input select

    textarea{
      resize: none;
      height: 110px;
    } //textarea

    input[type='submit']{
      background-color: $green-color;
      color: $color-white;
      box-shadow: none;
      border: none;
    }
  } //__form

  @at-root #{&}__form-title{
    color: #777;
    font-size: 18px;
    line-height: 20px;
  } //__form-title
  @at-root #{&}__form-sub{
    color: #000;
    font-size: 24px;
    line-height: 20px;
    font-weight: 900;
    margin-bottom: 15px;
  } //__form-sub

  @at-root #{&}__soc{
    background-color: #f8f8f8;
    height: 90px;
    border-bottom: solid 1px #d6d6d6;
  } //__soc
  @at-root #{&}__soc-btn{
    float:$left;
    width: 33%;
    height: 100%;
    text-align: center;
    padding: 15px 10px 0;
    transition: .2s;

    &:not(:last-of-type){
      border-#{$right}: solid 1px #d6d6d6;
    } //not last

    &:hover{
      box-shadow: 0 0 10px rgba(0,0,0,0.15);
    }

    img{
      display: block;
      margin: 0 auto 10px;
    }

    span{
      font-size: 16px;
      line-height: 16px;
      display: block;
      color: #415667;
    }

  } //__soc-btn
} //side-form


.sb-parent{
  span{
    padding: 1em;
    color: #f2f2f2;
    width: 100%;
    display: inline-block;
    position: relative;

    &:after{
      content: '>';
      transform: rotate(-90deg);
      position: absolute;
      top: 10px;
      #{$left}: 35px;
    }
  }
}
.sb-sub{
  display: none;

  &_rev{
    display: block;
  }
  li{
    a{
      padding: .7em;
      padding-#{$right}: 1em;
      font-size: 16px;
      font-weight: 400;
      color: #f2f2f2;
      width: 100%;
      display: inline-block;
      position: relative;
    }
  }
}
