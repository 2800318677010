.content-main{
  font-family:'Assistant',sans-serif;
  position: relative;
  z-index:100;
  background-color:#fff;
  box-shadow: 1px 2px 25px #dfdfdf;
}
.content-banner{
position: relative;
 @include respond-to('medium'){
     background-size: contain;
 } &__height{
       //width:$full-width;
     height:auto;
     margin: 0px;
     visibility: hidden;
 }
  &__image {
    background-repeat:no-repeat;
    background-position: center;
    background-size:cover;
    max-height: 60vh;
    @include respond-to('large'){
          height:auto;
         margin: 0px;
    }
  }
}

 .content-article {
   background-color: #fff;
   position:relative;
   line-height:1.5;
   @include respond-to('large'){
   width:100%;
    margin: 0px;
    padding:0;
   }
&__img{
  box-shadow: -6px 4px 29px 0px rgba(0,0,0,0.15),3px -8px 29px 0px rgba(0,0,0,0.15);
  float: left;
  margin: 4px 20px 20px 0px;
  width: 50%;

  &_form{
    float: right;
    margin: 0;
    max-width: 100%;
    max-height: 500px;
    width: auto;
    height: auto;
  }
}

    h1, &__title {
      font-size: 30px;
      font-weight: 500;
    	color:$main-color;

      @include respond-to('medium'){
        margin-right: 30px;
      }
   }

	 &_sub{
   font-size:16px;
   font-weight:400;
 	color:$main-color;
   }

	&__par {
		font-size: 16px;
		font-weight: 500;
    		color: $color-black;
  	}
  }

  .content-contact{

    &__box{
      text-align: $right;
      background:$contact-bg-color;
      border:1px solid $contact-bg-color;
      border-radius: 15px;
      padding:20px;
    }
    &__title{
      font-size:25px;
      font-weight:bold;

    }
    &__sub{
      font-size:20px;
      font-weight:400;
    }
  &__form{
    padding:10px;
    textarea , input {
      background: $input-bg;
      &::placeholder{
        color:$main-color;
        border-color:#4e4e4e;
  }
}
}

&__details{
  &_list{
    list-style-type: none;
    &_item{
      margin-bottom:50px;
    }
  }
  a{
    color:#777777;
      font-size:18px;
  }
  img{
    width:40px;
    height:auto;
    float:$right;
    margin-left:30px;
  }
  &_title{
    font-size:25px;
    font-weight:bold;
    padding:30px;
    color: $alt-title-color;
  }
}
}

.articles-btn{
  width: 100%;
  height: 175px;
  border: 1px solid #ddd;
  margin-bottom: 30px;
  box-shadow: none;
  padding: 15px;
  transition: .2s;

  &:hover{
    box-shadow: 1px 1px 30px rgba(0,0,0,.03);
  }

  &__title{
    color: $brand-color;
    margin: 0 0 15px;
    padding-bottom: 5px;
    border-bottom: 1px solid #999;
    font-size: 22px;
  }
  &__text{
    color: #000;
    font-size: 18px;
  }
} //articles-btn

.content-main-new{
  position: relative;
  overflow: hidden;
  min-height: 500px;
  margin:0px -30px;

  @include respond-to('medium'){
    margin: 0;
  }

  &__side{
    width:25%;
    float:right;
    padding:0px 30px;
    padding-right:50px;
    margin-bottom:-9999px;
    padding-bottom:9999px;
    background: linear-gradient(to right, #e8e8e8 0%, #fff 100%);
    box-shadow: 10px 0px 5px -5px rgba(0, 0, 0, 0.15) inset;
    @include respond-to('medium'){
      width:100%;
    }
  }

  &__content{
    width:75%;
    float:right;
    padding:0px 30px;
    margin-bottom:30px;
    @include respond-to('medium'){
      width:100%;
    }
  }

  &__content-full{
    width:100%;
    float:right;
    padding:0px 30px;
    margin-bottom:30px;
  }
}

.side_menu{
    margin-bottom: 70px;
    border-radius: 6px;
    background: #fff;
    transition: all 0.5s;
}
.side_menu:hover{
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
}


.side_bar{
  margin-bottom: 70px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  transition: all 0.5s;
  margin-top:30px;
  margin-bottom:0 !important;
  li{
    background-color:#fff;
    &:hoveR{
      background-color:#fff;
    }
  }
  a{
    position: relative;
    padding: 12px 20px;
    color: #828282;
    background: transparent;
    font-size: 15px;
    font-weight: normal;
    border-top: 1px solid #ccc;
    transition: color 0.25s, background 0.25s, border 0.25s;
    &:hover{
      background-color:#fff;
      color:$brand-color;
    }
  }
}


.warp_catalog_product{
    position: relative;
    display: block;
    margin: 0 0 20px 0;
    padding: 0;
}
.dir_rtl.with_side_bar .warp_catalog_product{
    padding-right: 40px;
    padding-left: 0;
}
.dir_ltr.with_side_bar .warp_catalog_product{
    padding-right: 0;
    padding-left: 40px;
}
.warp_catalog_product.onsale .catalog_product .warp_catalog_img::before{
    position: absolute;
    z-index: 1;
    top: -5px;
}
.dir_rtl .warp_catalog_product.onsale .catalog_product .warp_catalog_img::before{
    left: -5px;
}
.dir_ltr .warp_catalog_product.onsale .catalog_product .warp_catalog_img::before{
    right: -5px;
}
.lang_heb .warp_catalog_product.onsale .catalog_product .warp_catalog_img::before{
    content: url(../img/onsale_tag_heb.png);
}
.lang_eng .warp_catalog_product.onsale .catalog_product .warp_catalog_img::before{
    content: url(../img/onsale_img_eng.png);
}
.warp_catalog_product:hover, .warp_catalog_product:active, .warp_catalog_product:focus{
    text-decoration: none;
}
.catalog_product{
  border: 1px solid #fff;
  border-radius: 6px;
  box-shadow: 0 0 10px rgba(0,0,0,.1);
  transition: all .25s;
  margin-bottom: 30px;
  width: 100%;
  max-width: 947px;
  overflow: hidden;
}
.catalog_product:hover, .warp_catalog_product:active .catalog_product, .warp_catalog_product:focus .catalog_product{
    box-shadow: 0 0 15px rgba(0,0,0,.2);
}
.warp_catalog_product .warp_catalog_img{
    position: relative;
    width: 60%;
    float: right;
}
.warp_catalog_product .warp_catalog_img_0{
    height: 240px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
.warp_catalog_product:nth-child(odd) .warp_catalog_img{
    float: left;
}
.warp_catalog_product .warp_catalog_img img{
    height: 100%;
    max-width: initial;
    width: auto;
    transition: all 0.25s;
}

.catalog_item_title{
    padding: 16px 0;
    margin: 0 30px;
    color: $brand-color;
    font-size: 24px;
    font-weight: normal;
    border-bottom: 1px solid #e2e2e2;
}
.warp_catalog_detalis{
    position: relative;
    height: 240px;
    width: 40%;
    float: right;
}
.catalog_item_summary{
    height: 120px;
    padding: 10px 30px 0;
    font-size: 18px;
    color: #777;
}
.catalog_item_price{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border-top: 1px solid #e2e2e2;
    padding: 10px 30px;
    background: #f5f5f5;
    text-align: center;
    color: $brand-color;
}
.price_label, .catalog_label{
    color: #000;
}
.catalog_product .btn{
    margin-top: 20px;
    margin-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
}
.catalog_product .btn.add_from_catalog{
    _padding-top: 5px;
    padding-bottom: 5px;
}
.link_to_more_items{
    display: block;
    padding: 16px 0;;
    margin-top: 10px;
    margin-bottom: 40px;
    color: $brand-color;
    font-size: 25px;
    text-align: center;
    transition: all 0.25s;
    box-shadow: 0 0 10px rgba(0,0,0,.1);
}
.dir_rtl .link_to_more_items{
    margin-right: 40px;
}
.dir_ltr .link_to_more_items{
    margin-left: 40px;
}
.link_to_more_items:hover{
    text-decoration: none;
    box-shadow: 0 0 15px rgba(0,0,0,.3);
}

.side_form{
    position: relative;
    margin-top: 20px;
    padding: 24px 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0,0,0,.1);
    transition: all 0.5s;
}
.side_form:hover{
    box-shadow: 0 0 10px rgba(0,0,0,.3);
}
.side_form_title h2{
    padding-bottom: 15px;
}
.side_form_title h2{
    color: #000;
    font-size: 18px;
}
.side_form .checkbox{
    font-size: 11px;
    max-width: 80%;
}
.side_form .checkbox{
    font-size: 11px;
    max-width: 80%;
}
.side_form .control-label{
    display: none;
}
.side_form .checkbox .control-label{
    display: inline;
}
.side_form .form-group .col-sm-8{
    width: 100%;
    margin-right: 0;
    margin-left: 0;
}
.side_news{
    position: relative;
    margin-top: 20px;
    padding: 24px 20px;
    background: transparent;
    border-radius: 8px;
    _box-shadow: 0 0 10px rgba(0,0,0,.1);
    transition: all 0.5s;
    .form-control{
      margin-bottom:0;
    }
}
.side_news:hover{
    _box-shadow: 0 0 10px rgba(0,0,0,.3);
}
.side_news_title{
    padding: 20px 0;
    font-size: 20px;
    border-bottom: 1px solid #e2e2e2;
}
.side_news_title span{
    color: #0092ac;
}

.breadcrumb {
    padding: 8px 10px;
    font-size: 14px;
    list-style: none;
    border-radius: 0;
    background: #f5f5f5;
    margin-top: -20px;
    margin-right: -30px;
    padding-right: 30px;
    margin-bottom: 30px;

    @include respond-to('medium'){
      margin-right: 0;
    }
}

.breadcrumb a{
    color: #777;
}
.breadcrumb li:last-child{
    color: $brand-color;
}


.content-sidebar__nav{
  @include respond-to('medium'){
    display: none;
  }
}
