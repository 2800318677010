
#send_btn , .greenBtn{
  -moz-box-shadow:inset 0px 1px 0px 0px #99b801;
  -webkit-box-shadow:inset 0px 1px 0px 0px #99b801;
  box-shadow:inset 0px 1px 0px 0px #99b801;
  background:-webkit-gradient(linear, left top, left bottom, color-stop(0.05, #99b801), color-stop(1, #7c9501));
  background:-moz-linear-gradient(top, #99b801 5%, #7c9501 100%);
  background:-webkit-linear-gradient(top, #99b801 5%, #7c9501 100%);
  background:-o-linear-gradient(top, #99b801 5%, #7c9501 100%);
  background:-ms-linear-gradient(top, #99b801 5%, #7c9501 100%);
  background:linear-gradient(to bottom, #99b801 5%, #7c9501 100%);
  filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#99b801', endColorstr='#7c9501',GradientType=0);
  background-color:#99b801;
  -moz-border-radius:6px;
  -webkit-border-radius:6px;
  border-radius:6px;
  border:1px solid #7c9501;
  display:inline-block;
  cursor:pointer;
  color:#ffffff;
  font-family:Arial;
  font-size:15px;
  font-weight:bold;
  padding:6px 24px;
  text-decoration:none;
  text-shadow:0px 1px 0px #7c9501;
}
#send_btn:hover , .greenBtn:hover {
  background:-webkit-gradient(linear, left top, left bottom, color-stop(0.05, #7c9501), color-stop(1, #99b801));
  background:-moz-linear-gradient(top, #7c9501 5%, #99b801 100%);
  background:-webkit-linear-gradient(top, #7c9501 5%, #99b801 100%);
  background:-o-linear-gradient(top, #7c9501 5%, #99b801 100%);
  background:-ms-linear-gradient(top, #7c9501 5%, #99b801 100%);
  background:linear-gradient(to bottom, #7c9501 5%, #99b801 100%);
  filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#7c9501', endColorstr='#99b801',GradientType=0);
  background-color:#7c9501;
}
.greenBtn.blue{
  -moz-box-shadow:inset 0px 1px 0px 0px #3498db;
  -webkit-box-shadow:inset 0px 1px 0px 0px #3498db;
  box-shadow:inset 0px 1px 0px 0px #3498db; 
  border:1px solid #3498db;   
  background: #3498db;
  background-image: -webkit-linear-gradient(top, #3498db, #2980b9);
  background-image: -moz-linear-gradient(top, #3498db, #2980b9);
  background-image: -ms-linear-gradient(top, #3498db, #2980b9);
  background-image: -o-linear-gradient(top, #3498db, #2980b9);
  background-image: linear-gradient(to bottom, #3498db, #2980b9);
}
.greenBtn.blue:hover {
  background: #3cb0fd;
  background-image: -webkit-linear-gradient(top, #3cb0fd, #3498db);
  background-image: -moz-linear-gradient(top, #3cb0fd, #3498db);
  background-image: -ms-linear-gradient(top, #3cb0fd, #3498db);
  background-image: -o-linear-gradient(top, #3cb0fd, #3498db);
  background-image: linear-gradient(to bottom, #3cb0fd, #3498db);
}


#send_btn:active , .greenBtn:active {
  position:relative;
  top:1px;
}



#HpMdule , #welcome, #grades {
  float: right;
  margin: 22px auto;
}

.item_box img {
-moz-transition:-moz-transform 0.5s ease-in; 
-webkit-transition:-webkit-transform 0.5s ease-in; 
-o-transition:-o-transform 0.5s ease-in;
}

.item_box:hover img {
-moz-transform:scale(1.2); 
-webkit-transform:scale(1.2);
-o-transform:scale(1.2);
}

#HpMdule .item_box ,#grades .item_box {
  float: right;
  font-size: 13px;
  margin: 10px;
  width: 320px;
position:relative;
}

#HpMdule .item_box > div:first-child ,#grades .item_box > div:first-child {
  height: 100%;
  width: 100%;

}

#HpMdule .item_box > div.greenHover ,#grades .item_box > div.greenHover{
height: 100%;
width: 100%;
position: absolute;
top:0px;
left:0px;
background: rgba(0, 0, 0, 0) url("../images/green_opacity.png") repeat scroll 0 0;
opacity:0;
z-index:9;
}
#HpMdule .item_box:focus > div.greenHover, 
#grades .item_box:focus > div.greenHover,
#HpMdule .item_box:hover > div.greenHover, 
#grades .item_box:hover > div.greenHover{
opacity:1;
}

#HpMdule .item_image ,#grades .item_image {
  border-radius: 8px;
  box-shadow: 0 0 3px #555;
  float: right;
  height: 200px;
  overflow: hidden;
  width: 100%;
}
#HpMdule .item_image > a > img,#grades .item_image > a > img {
  height: 100%;
  width: 100%;
}
#HpMdule .item_title ,#grades .item_title {
  height: 48px;
  padding: 0 10px;
  position: absolute;
  text-align: center;
  top: 77px;
  width: 100%;
  z-index: 10;
}
#HpMdule .item_title > a ,#grades .item_title > a {
  background: rgba(0, 0, 0, 0) url("../images/green_opacity.png") repeat scroll 0 0;
  color: #fff;
  display: inline-block;
  font-size: 14px;
  font-weight: normal;
  height: 100%;
  line-height: 47px;
  margin: auto;
  min-width: 140px;
  padding: 0 10px;
  text-decoration: none;
  width: auto;
}

#welcome_blog {
  float: right;
  margin-top: 60px;
}
.article_box {
  float: right;
  font-size: 13px;
  height: 380px;
  margin: 10px;
  overflow: hidden;
  width: 320px;
}
#p_left .article_box {
  width: 300px;
height: 400px;
}
.article_box .item_image {
  border-radius: 8px;
  box-shadow: 0 0 3px #555;
  float: right;
  height: 200px;
  overflow: hidden;
  width: 100%;
}
.article_box .item_image > a > img {
  height: 100%;
  width: 100%;
}
.article_box .item_title {
  float: right;
  height: 48px;
  padding: 0 10px;
  width: 100%;
position: relative;
}
.article_box .item_title > a {
  color: #555555;
  display: block;
  font-size: 16px;
  font-weight: bold;
  height: 100%;
  line-height: 48px;
  text-decoration: none;
  width: 100%;
}
.article_box .item_summery {
  color: #555555;
  display: block;
  float: right;
  height: 90px;
  padding: 10px;
  width: 100%;
}

#p_left .article_box .item_summery {
  height: 108px;
}
.article_box .item_link {
  color: #555555;
height:100px;
padding: 0 10px;
  width: 100%;
display: block;
}
.article_box .item_link a{
  background: none repeat scroll 0 0 #9aba01;
  border-radius: 5px;
  color: #fff;
  display: inline-block;
font-size: 16px;
  margin-left: 10px;
  padding: 6px 10px;
}

#welcome .text {
  color: #555555;
  font-size: 16px;
}
#welcome .text a {
  background: none repeat scroll 0 0 #9aba01;
  border-radius: 5px;
  color: #fff;
  display: inline-block;
  margin-left: 10px;
  padding: 10px 15px;
}
#news{
width: 300px;
float:left;
}
#news #newsScroller {
  background: none repeat scroll 0 0 #f9b035;
  border: 3px solid #fff;
  padding: 10px;
  width: 280px;
  height: 158px;
  margin-top: 14px;
}

#news #newsScroller a.title {
  text-decoration: none;
  color: #666;
}

/*************************************************************************/
h1 , .head_title {
  background: url("../images/head_bg.png") no-repeat scroll center bottom rgba(0, 0, 0, 0);
  color: #000;
  font-size: 24px;
  margin-bottom: 20px;
  padding: 20px 0 40px;
  text-align: center;
  width: 100%;
  font-weight: normal;
}


h1 , .head_title > span {
  color: #9aba01;
}

.top_img > img {
  border: 5px solid #fff;
}

#content > div p span , #content > div p  , #content > div {
  color: #4e4d4d !important;
  font-size: 15px !important;
}

#content > div p > span , #content > div > p  , #content > div {
 padding:10px;
}
#c_content {
  margin-top: 260px;
  position: relative;
  z-index: 100;
}
.c_content {
  float: right;
  min-height: 400px;
  padding-top: 7px;
  width: 770px;
}
.c_content.search{
  width: 960px;
}

.c_content > div {
  padding: 10px 10px 0 10px ;

}
nav#right_menu {
  background: url("../images/left_menu_bg.png") repeat-y scroll left 0 ;
  float: right;
  min-height: 400px;
  width: 242px;
}

#right_column > div#right_menu {
  background: none repeat scroll 0 0;
  margin-top: -13px;
  padding: 0;
width: 230px;
}

#right_menu > a , #right_menu > div > a {
  border-bottom: 1px solid #fff;
  border-top: 1px solid #d2d2d2;
  color: #929292;
  display: block;
  font-size: 14px;
  line-height: 39px;
  padding-right: 5px;
  text-decoration: none;	
cursor:pointer;
}
#right_menu > a:first-child {
}
#right_menu > a:hover  , #right_menu > a.selected , #right_menu > a.open ,  #right_menu > div > a.selected {
  background: none repeat scroll 0 0 #fff;
  color: #1b75bb;
font-weight:bold;
}
#right_menu > div  {
  display:none;
}
#right_menu > div  > a.c_child {
  font-size: 12px !important;
  padding-right: 15px !important;
}


.trip_box:first-child {
  margin-right: 10px;
}

.catalog_page {
  padding-right: 10px;
}
.trip_box {
  float: right;
  margin: 14px 5px;
  padding: 0 !important;
  position: relative;
  border: 5px solid #fff;
  box-shadow: 0 0 3px #999;	
}
.trip_box .img {

}
.trip_box .title {
 background: url("../images/title_white_bg.png") repeat scroll left 0 ;   
  bottom: 0;
  height: 30px;
  line-height: 30px;
  position: absolute;
  text-align: center;
  width: 100%;
}
.trip_box .title a {
  color: #1b75bb;
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
}


/* Content
-----------------------------------------------------------------------------*/

#top_banner > img {
  width: 100%;
}

/* Articles
-----------------------------------------------------------------------------*/


/************************ Gallery ******************************/

.c_content.gallery > div {
  padding: 10px 0;
}
.gallery-item {
  border-radius: 5px;
  float: left;
  height: 290px;
  margin: 10px 15px;
  position: relative;
  text-align: center;
  width: 315px;
  @include respond-to('small') {
    height: 100%;
    margin: 0 auto;
  }
}
.gallery-item.first {
  margin-right: 0;
}
.gallery-item a {
  background: none repeat scroll 0 0;
  cursor: pointer;
  display: block;
  height: 100%;
  padding: 0;
  width: 100%;
}
.gallery-item a  img {
  border: 5px solid #fff;
  box-shadow: 0 0 3px #ccc;
  height: 100% !important;
  width: 100% !important;
}

.gallery-item span { 
  display: none;
position: absolute;
top: 0;
right: 0;
width: 100%;
height: 100%;
background: url(../images/hover.png) repeat center center;
cursor:pointer;
}
.gallery-item:hover span {
  display: block; 
}

/************************ Gallery ******************************/

#calendar > h2{
position:absolute;
}

.htabs > a {
  background: none repeat scroll 0 0 #d9d9d9;
  border-radius: 10px 10px 0 0;
  box-shadow: 0 0 4px #999 inset;
  color: #5f5f5f;
  cursor: auto;
  display: block;
  float: right;
  font-size: 16px;
  line-height: 39px;
  margin: 0 1px;
  padding: 0 15px;
  text-align: center;
  width: 109px;
cursor:pointer;
}
.htabs {
  position: relative;
  z-index: 4;
}
.tabs {
  margin-top: -4px;
  position: relative;
  z-index: 2;
}

.tabs > div.selected {
  display: block;
}
.htabs > a.active {
cursor:pointer;
}
.htabs > a:hover, .htabs > a.selected {
  background: none repeat scroll 0 0 #ffffff;
  box-shadow: 0 -2px 2px #ccc;
  color: #1b75bb;
}

.tabs > div {
overflow:hidden;
  box-shadow: 0 0 2px #ccc;
  display: none;
  float: right;
  margin-bottom: 20px;
  overflow: hidden;
  padding: 15px;	
min-height: 250px;
}
.tabs > div.selected  {
  display: block;
}

#content > div#contactUs {
  background: url("../images/contact_us.png") no-repeat scroll left 0;
  height: 74px;
  margin-top: -21px;
  padding: 4px 68px 0 30px;
  position: absolute;
  left: -35px;
  width: 294px;
}

#contactUs > a {
  color: #fff;
  display: block;
  font-size: 15px;
  font-weight: normal;
  height: 30px;
  line-height: 25px;
  text-decoration: none;
  width: 207px;
}

#contactUs > a:hover{
  text-decoration: underline;
}
.hr {
  box-shadow: 0 2px 3px #ccc;
  float: right;
  height: 1px;
  margin: 15px 0;
  padding: 0 !important;
  width: 100%;
}
.item_gallery {
  margin: 15px 0;
  padding: 0 !important;
  width: 100%;
float: right;
}


.item_gallery h2 {
  color: #999;
  font-weight: normal;
  padding-right: 12px;
}
.footer_1 ul {
  float: right;
  list-style: outside none none;
  margin: 0;
  padding: 0;
  width: 196px;
}
.footer_1 li {
  color: #8f8f8f;
  font-size: 12px;
  font-weight: bold;
  line-height: 24px;
}

#footer_warp {
background: none repeat scroll 0 0 #fff;
  float: right;
  min-height: 90px;
  position: relative;
  width: 100%;
  z-index: 999;
}
#footer_warp > div {
  margin: auto;
  padding-top: 20px;
  width: 980px;
}
#footer_warp div#footer_logo {
  float: right;
  width: 172px;
}
#footer_warp div#footer_text {
  float: right;
  padding-right: 15px;
  width: 612px;
}
.footer_1 {
  /*background: url("../images/border_bottom.png") repeat-x scroll 0 top;*/
  overflow: auto;
  padding: 20px 0; 
}
.footer_2 {
  float: right;
  line-height: 20px;
  text-align: right;
  width: 770px;
}
#footer_warp div#credit {
background: url("../images/border_bottom.png") repeat-x scroll 0 top;
  float: left;
  padding-bottom: 40px;
  padding-top: 24px;
  text-align: left;
  width: 100%;
}
.footer_1 li a {
  color: #555555;
  font-weight: normal;
}
#circleWarp {
  background: url("../images/circle_bg.png") no-repeat scroll center center rgba(0, 0, 0, 0);
  float: right;
  height: 906px;
  margin: 100px auto 0;
  overflow: hidden;
  position: relative;
  width: 1020px;
  z-index: 801;
}
#circleMenuWrap {
  background: url("../images/circule_shadow.png") no-repeat scroll center 809px rgba(0, 0, 0, 0);
  clear: both;
  height: 905px;
  padding: 30px 30px 60px;
  position: relative;
  width: 100%;
}
#circleMenu {
  clear: both;
  height: 800px;
  margin: 0 auto;
  position: relative;
  width: 800px;
}
#circleMenu #roundWrap {
  border-radius: 600px;
  height: 800px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 800px;
}

#circleMenu #compass {
  border-radius: 500px;
  height: 438px;
  left: 180px;
  overflow: hidden;
  position: absolute;
  top: 207px;
  width: 438px;
  z-index: 20;
}
#compass > div#text {
  border-radius: 150px;
  height: 218px;
  overflow-y: hidden;
  padding: 20px;
  position: absolute;
  right: 85px;
  text-align: center;
  top: 85px;
  width: 275px;
  z-index: 100;
}
#circleMenu #compass #arrow {
  background: url("../images/circle_menu_middle_compass_arrow.png") no-repeat scroll center center rgba(0, 0, 0, 0);
  height: 424px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  transition: all 0.3s ease 0s;
  width: 424px;
  z-index: 22;
}
#circleMenu a {
  background-position: 0 0;
  background-repeat: no-repeat;
  display: block;
  position: absolute;
}
#circleSpans {
  height: 800px;
  position: absolute;
  width: 800px;
}
#circleSpans a {
  color: #fff;
  cursor: pointer;
  font-size: 28px;
  font-weight: bold;
  text-shadow: 0 0 3px #333;
  word-wrap: break-word;
 text-align: center;
 display:bllock;
}
#a_item1 {
  right: 40px;
  top: 268px;
  width: 200px;
}
#a_item2 {
  right: 184px;
  top: 105px;
  width: 200px;
}
#a_item3 {
  left: 40px;
  top: 268px;
  width: 200px;
}
#a_item4 {
  left: 184px;
  top: 105px;
  width: 200px;
}
#a_item5 {
  bottom: 240px;
  left: 13px;
  width: 200px;
}
#a_item6 {
  bottom: 74px;
  left: 184px;
  width: 200px;
}
#a_item7 {
  bottom: 240px;
  right: 28px;
  width: 200px;
}
#a_item8 {
  bottom: 105px;
  right: 168px;
  width: 200px;
}
.quart {
  height: 400px;
  overflow: hidden;
  position: absolute;
  width: 400px;
}
#quart_1 {
  right: 0;
  top: 0;
  z-index: 10;
}
#quart_2 {
  left: 0;
  top: 0;
  z-index: 11;
}
#quart_3 {
  bottom: 0;
  left: 0;
  z-index: 13;
}
#quart_4 {
  bottom: 0;
  right: 0;
  z-index: 14;
}
#roundWrap a {
  color: #fff;
  cursor: pointer;
  font-size: 30px;
  font-weight: bold;
  text-shadow: 0 0 3px #333;
  word-wrap: break-word;
opacity:0;
}
#compass > div#link {
  border-radius: 5px;
  bottom: 95px;
  box-shadow: 1px 1px 3px #333;
  height: 33px;
  position: absolute;
  right: 161px;
  text-align: center;
  width: 115px;
  z-index: 200;
}
#compass > div#link.clean {
  box-shadow: none !important;
}
#compass > div#link:hover {
  box-shadow: 1px 2px 3px #333;
}
#link > a {
  display: block;
  font-size: 14px;
  height: 100%;
  line-height: 33px;
  text-shadow: none;
  width: 100%;
}
#circleMenu #item1 {
  height: 400px;
  right: 0;
  top: 0;
  width: 400px;
  z-index: 1;
}
#circleMenu .link1 {
  background: linear-gradient(to bottom, #d1be00 0%, #feec00 100%) repeat scroll 0 0;
}
#item1 > span {
  bottom: 55px;
  display: block;
  position: absolute;
  right: 17px;
  text-align: center;
  width: 194px;
}
#circleMenu #item2 {
  height: 400px;
  right: 198px;
  top: -85px;
  transform: rotate(45deg);
  width: 400px;
  z-index: 2;
}
#circleMenu .link2 {
  background: linear-gradient(to bottom, #799102 0%, #bc316a 100%) repeat scroll 0 0;
}
#item2 > span {
  display: block;
  position: absolute;
  right: 0;
  text-align: center;
  top: 90px;
  transform: rotate(-45deg);
  width: 225px;
}
#circleMenu #item3 {
  height: 400px;
  left: 0;
  top: 0;
  width: 400px;
  z-index: 3;
}
#circleMenu .link3 {
  background: linear-gradient(to bottom, #124d7e 0%, #2ba5de 100%) repeat scroll 0 0;
}
#item3 > span {
  bottom: 56px;
  display: block;
  left: 25px;
  position: absolute;
  text-align: center;
  width: 188px;
}
#circleMenu #item4 {
  height: 400px;
  left: 197px;
  top: -84px;
  transform: rotate(315deg);
  width: 400px;
  z-index: 4;
}
#circleMenu .link4 {
  background: linear-gradient(to bottom, #12622d 0%, #62b345 100%) repeat scroll 0 0;
}
#item4 > span {
  display: block;
  left: 15px;
  position: absolute;
  text-align: center;
  top: 91px;
  transform: rotate(-315deg);
  width: 225px;
}
#circleMenu #item5 {
  height: 400px;
  left: 0;
  top: 0;
  width: 400px;
  z-index: 1;
}
#circleMenu .link5 {
  background: linear-gradient(to bottom, #799102 0%, #bc316a 100%) repeat scroll 0 0;
}
#item5 > span {
  display: block;
  left: 30px;
  position: absolute;
  text-align: center;
  top: 45px;
  width: 170px;
}
#circleMenu #item6 {
  height: 400px;
  left: 200px;
  top: 83px;
  transform: rotate(225deg);
  width: 400px;
  z-index: 2;
}
#circleMenu .link6 {
  background: linear-gradient(to bottom, #dc6310 0%, #9aba01 100%) repeat scroll 0 0;
}
#item6 > span {
  bottom: 229px;
  display: block;
  position: absolute;
  right: -12px;
  text-align: center;
  transform: rotate(135deg);
  width: 226px;
}
#circleMenu #item7 {
  height: 400px;
  right: 0;
  top: 0;
  width: 400px;
  z-index: 1;
}
#circleMenu .link7 {
  background: linear-gradient(to bottom, #12622d 0%, #62b345 100%) repeat scroll 0 0;
}
#item7 > span {
  bottom: 246px;
  display: block;
  position: absolute;
  right: 27px;
  text-align: center;
  width: 173px;
}
#circleMenu #item8 {
  /* background: linear-gradient(45deg, #124d7e 0%, #2ba5de 51%, #124d7e 100%) repeat scroll 0 0; */
  bottom: -84px;
  height: 400px;
  right: 199px;
  transform: rotate(135deg);
  width: 400px;
  z-index: 2;
}
#circleMenu .link8 {
  background: linear-gradient(to bottom, #124d7e 0%, #2ba5de 100%) repeat scroll 0 0;
}
#item8 > span {
  display: block;
  position: absolute;
  right: 197px;
  text-align: center;
  top: 132px;
  transform: rotate(225deg);
  width: 221px;
}
#p_right h2 {
  background: url("../images/black_border.png") repeat-x scroll 0 bottom;
  color: #fff;
  padding: 0 10px 15px;
}
#p_right h2 a {
  color: #fff;
}
#reviewsScroller > div {
  margin: 20px 0;
}
marquee#reviewsScroller {
  height: 507px;
  overflow: hidden;
}
#reviewsScroller .reviews_text {
  color: #fff;
  font-style: italic;
  line-height: 22px;
  padding: 10px;
}
#reviewsScroller .titie {
  color: #f1ad15;
  font-weight: bold;
  padding: 10px;
}
#reviewsScroller .titie a {
  color: #f1ad15;
}
.social_links {
  bottom: 10px;
  position: absolute;

}


#login_box .inp2 {
  background: #e0e0e0 ;
  border-left: 1px solid #c7c7c7;
  border-top: 1px solid #c7c7c7;
  height: 32px;
  width: 199px;
}


.forumRowPost .plus {
  color: #cbd693;
  font-size: 21px;
  font-weight: bold;
  margin: 0 5px;
  text-shadow: 0 0 3px #000;
}
.forumRowTopic .plus , .attach_file .plus {
  color: #9ABA01;
  font-size: 21px;
  font-weight: bold;
  margin: 0 5px;
  text-shadow: 0 0 3px #000;
}


.attach_wrap {
  padding: 20px;
  text-align: center;
}
.attach_wrap img {
  max-width: 970px;
}



#forum > div#rss {
  background: #fff none repeat scroll 0 0;
  box-shadow: 0 0 3px #333;
  height: 141px;
  left: 0;
  overflow: hidden;
  padding: 15px;
  position: absolute;
  top: 11px;
  width: 312px;
}
#forum > div#rss iframe {
  border: medium none;
  height: 100%;
  width: 100%;
}


#rss h3 {
  background: #cbd693 none repeat scroll 0 0;
  box-shadow: 0 0 3px #333;
  line-height: 34px;
  padding-right: 10px;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}
#rssmarquee > ul {
  font-size: 14px;
  list-style: outside none none;
  margin: 0;
  padding: 0;
  top: 36px;
  width: 80%;
}
#rssmarquee li {
  border-bottom: 1px solid #ccc;
  margin: 10px 0;
  padding: 3px;
}
#rssmarquee li > span {
  display: block;
}
#rssmarquee li > span.title {
 font-weight: bold;
}
#rssmarquee span.updated {
  color: #999;
  font-size: 12px;
  text-align: left;
}


.input_warp > span:first-child {
  min-width: 99px;
}
.input_warp > span {
  display: inline-block;
}
.attach_file_name {
  width: 250px;
}

.credit {
  margin: 30px auto;
  width: 222px;
  float: left;
}
.simply_credit {
  animation: 3s steps(19) 0s normal none infinite running animated_cs_logo;
  background: rgba(0, 0, 0, 0) url("../images/simply-black.png") no-repeat scroll 0 0;
  display: block;
  margin: 15px auto;
  height: 22px;
  transition: all 0.5s ease 0s;
  width: 163px;
}



@-webkit-keyframes animated_cs_logo{
  0%{background-position:0 0;}
  100%{background-position: 0 -418px;}
}
@keyframes animated_cs_logo{
  0%{background-position:0 0;}
  100%{background-position: 0 -418px;}
}
 
.h2-auto-added{
  display: none;
}   

.EventsFullDiv { width: 100%; margin: 0px auto; }
.EventsFullTable { width: 100%; border-collapse: collapse; }
.EventsFullTable tbody td { border: 1px solid #aaaaaa; }
.EventsFullTable thead .nav .back { text-align:center; font-weight: bold; }
.EventsFullTable thead .nav .next { text-align:center; font-weight: bold; }
.EventsFullTable thead .nav .title { text-align:center; font-weight: bold; }
.EventsFullTable thead .days th {  text-align:center; width: 14%; background: #aaaaaa; color:#ffffff; font-weight: bold; }
.EventsFullTable tbody .thisday .title{  font-weight: bold; color: white; background: #B60300; }
.EventsFullTable tbody .evday .title{  font-weight: bold; color: white; background: #008800; }
.EventsFullTable tbody .day .title{  font-weight: bold; color: #000000; }
.EventsFullTable tbody .passday {  width: 14%;  color: #999999; }
.EventsFullTable tbody td { vertical-align: top; width: 14%; padding: 2px; }
.EventsFullTable tbody td:hover .title{ border: 1px solid black; }
.EventsFullTable tbody td .title  { text-indent: 5px; background: #dddddd; border: 1px solid white; }
.EventsFullTable tbody td .text  { border-bottom: 1px dotted #aaaaaa; }
.EventsFullTable tbody td .dummy  { height: 50px; }
.EventsFullTable tbody td .text .time { color: #111111; }
.EventsFullTable tbody td .text .desc  a{ text-decoration: none; color: #222222; }
.EventsFullTable tbody td .text .desc  a:hover{ text-decoration: underline;  }
.EventsFullTable tbody td .text .desc { text-indent: 5px;  }
.EventsFullTable tbody .passday .text .time { color: #999999; }
.EventsFullTable tbody .passday .text .desc  a { color: #999999;  }

.EventsDiv {
    margin: 0 auto;
    padding-top: 0px;
    position: relative;
    width: 100%;
}
#calender_bg {
    background: url(../images/calender-bg.png) no-repeat;
    height: 215px;
    left: 0;
    position: absolute;
    top: 0;
    width: 133px;
}

#EventsTableWarp {
    background: none repeat scroll 0 0 #fff;
    padding: 7px 10px 7px 11px;
    width: 303px;
}

#calender_nav {
    background: url("../images/calender_nav_bg.png") no-repeat scroll 103px 0 rgba(0, 0, 0, 0);
    color: #6d6d6d;
    font-size: 16px;
    height: 38px;
    padding-right: 132px;
    padding-top: 10px;
}
.EventsTable {
    border-collapse: collapse;
    height: 175px;
    width: 281px;
}
.EventsTable tbody td {
    background: url(../images/calender-td-bg.png) no-repeat;
    font-size: 15px;
    font-weight: bold;
}

.EventsTable thead .nav .back { cursor: pointer;text-align:center; font-weight: bold; }
.EventsTable thead .nav .next { cursor: pointer;text-align:center; font-weight: bold; }
span#date_nav {
    display: inline-block;
    width: 98px;
    text-align: center;
}

.arrow-pink-left {
    height: 11px;
    width: 11px;
    cursor: pointer;
    background: url(../images/arrow_pink.png) no-repeat 0 0 ;
    display: inline-block;
    margin: 0 5px;
}

.arrow-pink-right {
    height: 11px;
    width: 11px;
    cursor: pointer;
   background: url(../images/arrow_pink.png) no-repeat -11px 0;
    display: inline-block;
    margin: 0 5px;
}

.EventsTable thead .nav .title { text-align:center; font-weight: bold; }
.EventsTable thead .days th {
    background: none repeat scroll 0 0 #26bbe5;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    border-left: 1px solid #cbe5ec;
    border-right: 1px solid #cbe5ec;
	 height: 30px;
}
.EventsTable tbody .noday { text-align:center; }

.EventsTable tbody .thisday {
    color: #ffffff;
    cursor: pointer;
    font-weight: bold;
    text-align: center;
    background: url("../images/calender-td-bg.png") no-repeat scroll 0 -58px ;
}
.EventsTable tbody .evday { cursor: pointer; text-align:center; color: #ffffff; font-weight: bold; background: url("../images/calender-td-bg.png") no-repeat scroll 0 -28px;}
.EventsTable tbody .day { cursor: pointer; text-align:center; color: #000000; }
.EventsTable tbody .passday { cursor: pointer; text-align:center; color: #999999; }

.EventsList { width: 100%; border-collapse: collapse; }
.EventsList td { padding: 5px; border-bottom: 1px solid #A7A6A4; font-weight: bold; }
.EventsList .date { width: 15%; text-align: center; }
.EventsList .title a { text-decoration: none; color: #000000; }
.EventsList .title a:hover { text-decoration: underline; }
.EventsList .location {  }
.EventsList .cat { background: #96C61A; color: white; }

.forumTree { margin: 5px; border:1px solid #eeeeee; }
.forumTree .title  a {  color: green; font-weight: bold; font-size: 13px;  }
.forumTree .summary  { color: #888888; }
.forumCat {  }
.forumCat .title  {  font-weight: bold; font-size: 14px; color: #444444; }

.forumCat .summary {
    color: #888888;
    padding: 20px 0 40px;
}
.forumCat .items  {  margin-bottom: 10px;  }
.forumCat .items a {
    border: 1px solid #eeeeee;
    box-shadow: 0 0 3px #333;
    color: #000;
    display: block;
    float: right;
    font-weight: bold;
    height: 100px;
    margin: 10px;
    padding-top: 0;
    text-align: center;
    text-decoration: none;
    width: 100px;
}
.forumCat .items a:hover  { border:1px solid red;  }
.forumNav .info1 { float: right; }
.forumNav .info2 { float: left; }
.forumPage .info1 { float: right; }
.forumPage .info2 { float: left; }
.forumMod { font-weight: bold;  }
.forumTable { border-collapse: collapse; width: 100%; }
.forumTable .rowMember { text-align: center; white-space:nowrap; width: 15%;  }
.forumTable .rowMember a {  text-decoration: none; font-size: 10px; color: #666666; }
.forumTable .rowDate { text-align: center; white-space:nowrap; width: 15%; direction: ltr; font-size: 10px; color: #666666;}
.forumTable td { border: 1px solid #cccccc; padding: 2px;  }
.forumRowTopic td {
    background: #cbd693 none repeat scroll 0 0;
    font-size: 14px;
    padding: 6px;
}
.forumRowPost td {
    background: #eeeeee none repeat scroll 0 0;
    font-size: 14px;
    padding: 6px;
}

.forumPost .text {
    color: #565656;
    font-size: 14px;
    margin-bottom: 15px;
    padding: 10px;
    width: 713px;
}

.forumPost a {
    color: #9aba01;
    display: inline;
    font-weight: bold;
    margin-bottom: 12px;
}
.forumRowTopic td a { color: black; font-weight: bold; }
.forumRowPost td a { color: black;  }
.forumNC { font-size: 10px; color: #666666; margin: 0px 5px 0px 5px; }
.forumPost .title {  }
.forumPost .member { float: left; margin: 3px; width: 80px; }
.forumPost .attach { margin-bottom: 10px;  }
.forumPost .links { margin-bottom: 10px;  }
.forumTable .closePost { display: none;  }
.selectedTitleForum td { background: #F9FC5F; }
.selectedPostForum td {  }
.forumTable .unvalidpost td { background: #FC8D83; }

.siderow { }
.title2 { }
.btn2 { }
.btn3 { }
.inp2 { width: 150px; background: #e0e0e0 ;  border-left: 1px solid #c7c7c7;    border-top: 1px solid #c7c7c7;    height: 28px;}
.inp3 { width: 200px; height: 90px; }

/* ------------------[ SCROLLER ] ---------------------------------------- */

.csmeScroller {}
.csmeScroller {
    height: 160px;
}
.csmeScroller .item { margin: 0px 0px 5px 0px; }
.csmeScroller .item .thumb img { margin: 0px 2px 0px 2px; }
.csmeScroller .item .title { display: block; font-weight: bold; color: #000; font-size: 16px; }
.csmeScroller .item .description { display: block; font-size: 14px; color: black; }
.csmeScroller .item .pubDate { display: block; font-size: 10px; color: #000;  font-weight: bold;text-align:left;}

.csmeScrollerSide { white-space: nowrap; }
.csmeScrollerSide .item { white-space: nowrap; margin: 0px 2px 0px 2px; }
.csmeScrollerSide .item .thumb img { margin: 0px 2px 0px 2px; }
.csmeScrollerSide .item .title { white-space: nowrap;display: block; font-weight: bold; color: #333333; }
.csmeScrollerSide .item .description { white-space: nowrap; display: block; font-size: 12px; color: #666666; }
.csmeScrollerSide .item .pubDate { white-space: nowrap; display: block; font-size: 10px; color: #333333;  font-weight: bold;}

/* ------------------[ FORMS ] ---------------------------------------- */

.csform { margin: 10px; }
.csform .cstitle { color: black; font-weight: bold; display: block; font-size: 12px; }
.csform .csrequired { color: red; font-size: 12px; margin: 2px; margin-right: -6px; }
.csform .csholder { display: block;  }

.csform .csholder .csradio { display: block; }
.csform .csholder .csselect {  }

.csform .csholder .csinput {
    background: none repeat scroll 0 0 #fefefe;
    border: 2px solid #eee;
    border-radius: 5px;
    box-shadow: 0 0 3px #eee;
    font-family: arial;
    height: 22px;
    width: 200px;
	font-weight:bold;
}
.csform .csholder .cstextarea { font-family: arial; width: 200px; height: 90px; overflow: auto; }
.cstextarea { 
	background: none repeat scroll 0 0 #fefefe;
    border: 2px solid #eee;
    border-radius: 5px;
    box-shadow: 0 0 3px #eee;
	font-weight:bold;
 }
.csform .csnotes { display: block; color: #666666; font-size:10px; }
.csform .cserror { background: yellow; font-weight: bold; color:red; }

/* ------------------[ ORDERS ] ---------------------------------------- */

.undocorder td    { border-bottom:none !important;	}
.docorder { width: 100%; margin: auto; border-right: 1px solid #F7F7F7; border-left: 1px solid #F7F7F7; }
.docorder th { border-bottom: 1px solid white; background: #F7F7F7; padding: 2px; height: 30px; font-weight: bold; color: #666666; font-size: 14px; }
.docorder td { border-bottom: 1px solid #F7F7F7; padding: 2px; height: 30px; }
.docorder_addons { margin: 0px 10px 0px 10px; font-size: 10px;}
.docorder_addons_units { font-size: 10px; color: #666666; }
.docorder_addons_group { font-size: 10px; color: #666666; }
.docorder_addons_title { font-size: 10px; color: #666666; }
.docorder_addons_price { font-size: 10px; color: #666666; }
.docorder_label { width: 30%; color: #666666; border-bottom: 1px solid #F7F7F7;}
.docorder_value { width: 70%; border-bottom: 1px solid #F7F7F7; }
.docorder_title { font-weight: bold; font-size: 14px; border-bottom: 1px solid #666666; color: #666666;}

/* ------------------[ Table Lists ] ---------------------------------------- */

.sortable, .datatable{ 	font-size:12px;	font-family: Verdana,  Arial, sans-serif;	border-left:1px solid #ccc;	border-bottom:1px solid #ccc;	border-collapse:collapse;	padding:5px 0;	background: white;	}
.sortable th,.datatable th{	font-size:12px;	background-color:#fff;	border-top:1px solid #ccc;	border-right:1px solid #ccc;	border-bottom:1px solid #ccc;	height:30px;	background:#fff url(../images/tbl-bg.jpg) bottom repeat-x;	}   
.sortable th a,.datatable th a{	color:#000;	padding:4px;	font-variant: small-caps;	font-weight:bold;	text-decoration:none;	font-size: 12px;	}
.sortable a:active,.sortable th a:hover,.datatable a:active,.datatable th a:hover{	color:#000;	text-decoration:underline;	}
.sortable th a:visited,.datatable th a:visited{	color:#000;	text-decoration:none;	}
.sortable a.sortheader,.datatable a.sortheader{	text-decoration: none;	display: block;	}
table.sortable span.sortarrow,table.datatable span.sortarrow{	color: black;	text-decoration: none;	}
table.sortable th .cell,table.datatable th .cell{	border-bottom:1px solid #666;	border-top:1px solid #666;	}
.sortable tr,.datatable tr{	padding:0;	}
.sortable td,.datatable td{	padding:4px 0;	border-right:1px solid #ccc;	}
.sortable .cell-inner,.datatable .cell-inner{	border-right:1px solid #666;	padding:3px;	}
.sortable .cell-inner-end,.datatable .cell-inner-end{	border-right:none;	padding:3px;	}
.sortable a ,.datatable a{	color:#00566a;	text-decoration:none;	}
.sortable .cell,.datatable .cell{	border-bottom:1px solid #ccc;	border-right:1px solid #ccc;	padding:0;	}
.tdshade2 {	padding:5px 0 5px 0;	background-color: #f3f3f3;	}
.tdtopbo {	border-top:1px solid #ccc;	}
thead .arrow {	font-family: webdings; 	color: black; 	padding: 0; 	font-size: 10px;	height: 11px; 	width: 10px; 	overflow: hidden;	margin-bottom: 2; 	margin-top: -3; 	padding-bottom: 2;		}






.main-gallery {
	padding-top: 20px;
	padding-bottom: 20px;
}

.gallery-item {
	width: 30.8%;
	float: $right;
	position: relative;
	display: block;
	overflow: hidden;
	// height: 350px;
	text-align: center;
	box-shadow: 0 0 1px 1px #fff;
	@include respond-to('small') {
		width: 50%;
	}  
	@include respond-to('extra-small') {
		width: 100%;
		max-width: 400px;
		float: none;
		margin: 0 auto;
	}
	&_long {
		// width: 50%;
		& .gallery-item__wrapper {
			padding-bottom: 50%;
			@include respond-to('small') {
				padding-bottom: 100%;
			}
		}
		@include respond-to('extra-small') {
			width: 100%;
		}
	}
	&__wrapper {
		overflow: hidden;
		position: relative;
		width: 100%;
		padding-bottom: 78%; // control box proportions 
		@include respond-to('small') {
			padding-bottom: 100%;
		}
	}
	&__content {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	&:hover {
		.gallery-item__img {
			transform: scale3d(1.1, 1.1, 1.1);
		}
		.gallery-item__overlay {
			opacity: 1;
		}
	}
	&__img {
		display: block;
		max-width: 100%;
		height: 100%;
		width: 100%;
		transform: scale3d(1, 1, 1);
		transition: transform 0.15s ease 0s;
	}
	&__overlay {
		position: absolute; 
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: #9aba01;
		opacity: 0;
		transition: opacity 0.15s ease 0s;
	}
	&__icon {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		& i {
			font-size: 40px;
			color: #fff;
		}
	} 
	&__text {
		position: absolute;
		bottom: 35px;
		left: 50%;
		display: block;
		width: 70%;
		transform: translateX(-50%);
		text-align: center;
		color: #ffffff;
		font-size: 18px;
		font-weight: 300;
		@include respond-to('medium') {
			font-size: 16px;
			font-weight: 400;
		}
	}
}

.title-gal{
  @include respond-to('medium') {
    margin-right: 0 !important;
  }
}
.cat-box-proj{
  position: relative;
  display: block;
  max-width: 350px;
  width: 100%;
  height: 350px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 30px !important;
  &:hover{
    text-decoration: none;
  }
  @include respond-to('extra-small') {
    margin-bottom: 30px;
  }
  &__title{
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    padding: 10px;
    text-align: center;
    background: #1760ab;
    &_style{
      font-size: 22px;
      font-weight: 600;
      color: #fff;
    }
  }
}

.cat-box-flex{
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}