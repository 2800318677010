.home-form {
  text-align: $right;
  height: 70px;
  z-index: 2;



   @include respond-to('medium'){
      position: relative;
       bottom:100px;
    }

  &__container{
  height: 70px;
  position: relative;
  border: 0px #efefef solid;
  box-shadow: #cecece 0px 1px 25px;
  @include respond-to('extra-small'){
    position: relative;
  }
}

/* arrow box */

&__arrow {
  height: 70px;
  display: inline;
  position: relative;
  top: 20px;

  &_head{
    position: absolute;
    #{$right}: 570px;
    width: 0;
    height : 0;
    border-top: 35px solid transparent;
    border-bottom: 35px solid transparent;
    border-#{$right}: 35px solid #efefef;
    @include respond-to('large'){
display:none;
       }
}
&_title {
  float: $right;
  display: inline;
  position: absolute;
  #{$right}: 0;
  background: linear-gradient(to $right, #efefef 50%, #fff 100%);
  line-height: 70px;
  border-top-#{$right}-radius: 8px;
  border-bottom-#{$right}-radius: 8px;
  font-size: 23px;
  color: $main-color;
  @include respond-to('large'){
position: relative;
top:0px;
line-height: 70px;
font-size:1.9rem;
width:105%;
text-align:center;
     }
  @include respond-to('medium'){
position: relative;
margin-top:200px;
line-height: 55px;
font-size: 1.9rem;
width:100%;
text-align:center;
     }
    @include respond-to('small'){
  position: relative;
  top:0;
  line-height: 55px;
  font-size: 1.8rem;
  width:100%;
  text-align:center;
       }
       @include respond-to('extra-small'){
         padding:20px;
         color:$main-color;
           line-height: 1;
  font-size: 1.7rem;

       }
}
} //arrow

  &__inputs {
  float: $right;
  display: inline-block;
  vertical-align: middle;

      @include respond-to('medium'){
    position: relative;

    width:100%;
    line-height: 15px;
    font-size: 1.5rem;
      text-align:center;
         }
    &_email{
      float:$right;
      position: relative;
      line-height: 47px;
      top: 10px;
      @include respond-to('large'){
      width:45%;
         }
      @include respond-to('medium'){
            float:none;
        position: relative;
        top:0px;
        width:100%;
        padding:0px;
        line-height: 35px;
        font-size: 2rem;
          text-align:center;
     }
    }
    &_checkbox {
      float:$right;
      opacity: 0;
      position:relative;
      &:checked{
        +label{
          &:after{
            opacity: 1;
            transform: rotate(-45deg) scale(1);
          } //after
        } //label
      } //checked
    &-label {
      color: $main-color;
      font-size: 16px;
      font-weight: 500;
      position:relative;
      display:inline-block;
      width:35%;
      top:10px;
      #{$right}:25px;
      padding:0 30px;
      @include respond-to('large'){
    position: relative;
    top:10px;
    line-height: 1.4;
    font-size:1.4rem;
    width:35%;
    text-align:center;
         }
         @include respond-to('medium'){
              width:50%;
            top:5px;
                font-size:1.8rem;
         }
      @include respond-to('small'){
        position: relative;
        top:7px;
  width:100%;
        display:inline;
        padding:0px;
        line-height: 35px;
        font-size: 1.8rem;
     }

    } //__checkbox-label

    &-controller{
      &:before{
        content: '';
        float:$right;
        position:absolute;
        width: 20px;
        height: 20px;
        border: 1px solid #555;
        display: block;
        background: linear-gradient(to bottom, $color-black 0%, $main-color 20%, $brand-color 100%);
        box-shadow: inset 0px 1px 1px color-white, 0px 1px 3px rgba(0,0,0,0.5);
        top: 23px;
          @include respond-to('small'){
              margin-top: 35px;
           }
        @include respond-to('medium'){
           margin-top:35px;
        }

      } //before
      &:after{
        content: '';
        position: absolute;
        top:28px;
        padding-#{$right}:15px;
        transform: rotate(-30deg) scale(.75);
        border-bottom: solid 2px #fff;
        border-#{$left}: solid 2px #fff;
        width: 12px;
        height: 7px;
        opacity: 0;
        transition: .25s;
        &:hover{
          opacity:0.7;
        }
        @include respond-to('medium'){
           margin-top:35px;
        }
              @include respond-to('small'){
              margin-top: 35px;
                 }

      } //after
    } //__checkbox-controller

  } /*inputs */
  &_submit{
    color:$color-white;
    font-weight: 700;
    font-size:20px;
    position: relative;
    margin-top:0px;
    height:54px;
    #{$right}:30px;
    background: linear-gradient(to bottom, $color-black 0%, $main-color 20%, $brand-color 100%);
    padding:0px 15px;
    @include respond-to('large'){


    }
       @include respond-to('small'){
                padding:0px 10px;
   height:40px;
  margin-top:10px;
                     }

  }
}
}
