.home-wrapper {
  position: relative;
  height: 1000px;
  max-height: 75vh;
  margin: 0;
  /*.box{
    height: 100%;
  } */
}
//home-wrapper

.main-banner {
  width: 100%;
  margin-top: 0;
  height: 100%;
  overflow: hidden;

  img {
    opacity: 0;
  }
  @include respond-to('small') {
    margin-top: 50px;

    img {}
  }
  //---small

  &__single {
    position: relative;
    width: 100%;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
//main-banner

.home-banner {
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 1;
  @include respond-to('medium') {
    background-color: #fff;
  }
  /*  &__wrapper{
    overflow: hidden;
    @include respond-to('extra-small'){

              height: 50vh;

    }
  }*/
  /*&__video{
    position: relative;
    min-width: 100%;
    width: $max-width;
    z-index: -100;
    background: url(../videos/Amirim.png) no-repeat;
    background-attachment: fixed;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    @include respond-to('medium'){
      width:auto;
    }
    @include respond-to('smallest'){
      width:auto;
      height:auto;
    }
  }*/
  &__overlay {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    @include respond-to('huge') {
      //top:  -52vh;
    }
    @include respond-to('large') {
      //  top:  -48vh;
    }
    @include respond-to('medium') {
      //         top:  -24vh;
    }
    @include respond-to('small') {
      //      top:  -95vh;
    }
    @include respond-to('smaller') {
      //            top:  -20vh;
    }
    @include respond-to('extra-small') {
      position: relative;
    }
  }

  &__text {
    /* overlay text  (green & blue ) */
    font-family: $banner-font-stack;
    position: relative;
    float: $left;
    margin: 0 auto;
    //#{$left}:0;
    z-index: 7;
    color: $color-white;
    font-weight: bold;
    text-shadow: 1px 2px 3px rgba(01, 01, 01, 0.25);
    width: 333px;
    margin-#{$left}: 40px;
    z-index: 19;
    @include respond-to('small') {
      padding: 0;
      width: 40%;
      //  #{$left}:-6vh;
    }
    @include respond-to('smaller') {}
    @include respond-to('extra-small') {
      position: relative;
      margin-#{$left}: 0px;
      width: 100%;
      padding: 10px;
    }

    &_link {
      //position: relative;
    }
    //_link

    &_title {
      background: $banner-title-bg-color;
      font-size: 32px;
      margin-bottom: 7px;
      border-radius: 6px;
      width: 333px;
      padding: 20px 15px;
      font-weight: 700;
      margin-top: 0;
      @include respond-to('medium') {
        font-size: 18px;
        width: auto;
        padding: 15px 7px;
      }
      @include respond-to('small') {
        font-size: 16px;
        padding: 20px 15px;
      }
      @include respond-to('extra-small') {
        font-size: 18px;
        padding: 10px;
        text-align: center;
      }
    }

    &_sub {
      background: $banner-sub-bg-color;
      font-size: 21px;
      margin-bottom: 7px;
      border-radius: 6px;
      padding: 13px 30px;
      text-align: justify;
      position: relative;
      @include respond-to('medium') {
        font-size: 16px;
        width: auto;
        margin-bottom: 7px;
      }
      @include respond-to('small') {
        padding: 7px 4px;
        text-align: center;
      }
      @include respond-to('extra-small') {
        padding: 5px;
      }
    }

    &_arrow {
      /* position :relative;
        bottom:0px;
        #{$left}:80px;
         transition: all 0.5s ease;
        font-family:'Trebuchet MS' serif;
         margin: 0;
        font-size: 14px;
        font-weight: 400;
        background:$banner-arrow-bg-color;
        border-radius: 10px 2px 2px 10px;
        width:23px;
        text-align: center;
        line-height:13px;
        transform:scale(1.5,3);*/
      position: absolute;
      bottom: 0;
      #{$right}: -50px;
      margin: 0;
      font-size: 24px;
      font-weight: 400;
      background: $banner-arrow-bg-color;
      border-radius: 5px;
      width: 40px;
      height: 40px;
      text-align: center;
      line-height: 38px;

      &:hover {}
      @include respond-to('medium') {
        #{$left}: 60px;
      }
      @include respond-to('small') {
        #{$left}: 60px;
      }
      @include respond-to('extra-small') {
        display: none;
      }
      @include respond-to('smallest') {}
    }
  }

  &__navbar {
    position: relative;
    float: $right;
    //#{$right}:0;
    z-index: 7;
    @include respond-to('medium') {}
    @include respond-to('small') {
      top: 25vh;
    }
    @include respond-to('smaller') {
      top: 0;
    }
    @include respond-to('extra-small') {}
    @include respond-to('smallest') {}

    &_menu {
      position: relative;
      margin: 0 30px 30px;
      padding: 0 30px;
      text-align: center;
      z-index: 7;
      list-style-type: none;
      @include respond-to('medium') {
        z-index: 8;
        margin: 5px;
        padding: 0;
      }
    }

    &_item {
      z-index: 3;
      transition: all 0.5s ease;
      display: block;
      width: 125px;
      height: 127px;
      position: relative;
      background: rgba(255,255,255,0.9);
      transition: all 0.5s ease;
      border-radius: 4px;
      border: 2px $light-border-color solid;
      margin-top: 3px;
      $ni: &;
      @include respond-to('medium') {
        top: 10vh;
        display: inline-block;
        padding: 0;
        max-height: 12vh;
        width: auto;
      }
      @include respond-to('small') {
        max-height: 18vh;
      }
      @include respond-to('smaller') {
        max-height: 18vh;
      }
      @include respond-to('extra-small') {
        max-height: 18vh;
      }
      @include respond-to('smallest') {
        max-height: 10vh;
        max-width: 10vh;
      }

      &:hover {
        background: transparentize(rgba(255,255,255,0.75),0.25);
        cursor: pointer;
      }

      &_img {
        //   filter:  brightness(0%) invert(100%);
        width: auto;
        max-height: 70%;
        position: relative;
        padding: 15px 0;
        @include respond-to('small') {
          padding: 0;
        }
      }

      &_text {
        left: 0;
        right: 0;
        white-space: nowrap;
        border-top: 1px transparentize(#9F9B9B,0.25) solid;
        padding: 10px 0;
        @include respond-to('medium') {
          font-size: 12px;
          padding: 0;
          white-space: normal;
        }
        @include respond-to('smallest') {
          font-size: 9px;
          padding: 0;
          white-space: nowrap;
        }

        #{$ni}:first-of-type & {
          color: $green-color;
        }

        #{$ni}:nth-of-type(2) & {
          color: $blue-color;
        }

        #{$ni}:nth-of-type(3) & {
          color: $brown-color;
        }
      }
    }
  }
}

.warp_slider {
  padding-top: 40px;
  padding-bottom: 40px;
  position: absolute;
  bottom: -15px;
  background: 0 0;
  z-index: 10000000000;
  width: 680px;
  max-width: 100%;
  left: 0;
  right: 0;
  margin: 0 auto;

  @include respond-to('huge') {
    position: relative;
    float: $right;
    bottom: unset;
    left: unset;
    right: unset;
  }
}

.warp_slider a {
  color: #fff;
  text-align: center;
  max-width:134px;
  width: 20%;
  float: $right;

  @include respond-to('extra-small') {
    color: #000;
  }
}

.warp_slider .slick-initialized .slick-slide {
  text-align: center;
  _width: 150px !important;
}

.warp_slider .warp_img {
  height: 100px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0 17px;
  transition: all 1s;
  background: transparent;

  @include respond-to('extra-small') {
    margin: 0 10px;
    height: 75px;

    img{
      max-width: 75%;
    }
  }
}

.warp_slider .warp_img:hover {
  background: $brand-color;
  box-shadow: 0 0 15px rgba(0,0,0,.25);
}

.warp_slider .slider img {
  _height: 50px;
  width: auto;
}
