.home-grid {
	font-family: $alt-font-stack;
	display: block;
	padding-top: 0;
	padding-bottom: $grid-padding-bottom;
	position: relative;
	top: 0;
	margin: 0 auto;
	text-align: $right;
	z-index: 4;
	background: $color-white;
	box-shadow: 1px 2px 25px #dfdfdf;
	border-bottom: inset 4px solid darken(#dfdfdf, 0.2);
	@include respond-to('medium') {
		top: 0;
	}
	@include respond-to('small') {}



	&__link {
		display: block;
	}
	//__link

	&__wrapper {}

	&__img {
		background-repeat: no-repeat;
		background-size: cover;
		padding: calc((100% - 30px) / 3);
		position: relative;
		margin-top: $grid-padding-top;
		background-position: center;
		transition: transform  2s ease-in;


		/* == = large  */
		&_lg {
			height: 550px;
			@include respond-to('medium') {
				height: 240px;
			}
		}
		@include respond-to('small') {
			background-repeat: no-repeat;
			background-position: center center;
			background-size: cover;
		}
	}
	&__img-img{
		max-width: 100%;
		max-height: 1px;
		opacity: 0;
	}
	/*  =end  large  */

	&__text {
		z-index: 2;
		position: absolute;
		bottom: 0;
		transition: all 0.5s ease;
		width: 100%;

		&_lg {}

		&_title {
			color: lighten($main-color,5);
			background: transparentize($color-white,0.10);
			line-height: 33px;
			font-size: 25px;
			padding: $gtpy $gtpx;
			height: 30px;
			margin: 0;
			@include respond-to('small') {
				font-size: 2.1rem;
				line-height: 3.5rem;
			}
		}

		&_sub {
			background: transparentize($color-white,0.10);
			color: lighten($main-color,10);
			font-size: 16px;
			padding: $gtpy $gtpx;
			padding-bottom:4px;
		}
		// 	&_price
		// {
		// 		background: transparentize($bg-color,0.25);
		// 		color: $color-white;
		// 		position: relative;
		//     float:$left;
		//     padding:10px 13px ;
		//
		// 		&_start
		// {
		// 			font-size: 1.3rem;
		// 			}
		// 		}
		// 	&_num
		// {
		// 		font-size: 1.9rem;
		// 		font-weight: 700;
		// 		}
	}
}

.home-content{
	margin: 50px 0 20px; 

	h2{
		text-align: center;
	}
	p{
		font-size: 18px;
		color: #000;
	}
}
