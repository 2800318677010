// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------

@font-face {
    font-family: 'Open Sans Hebrew';
    font-style: italic;
    font-weight: 300;
    src: url(fonts/opensans/opensanshebrew-lightitalic-webfont.eot);
    src: url(fonts/opensans/opensanshebrew-lightitalic-webfont.eot?#iefix) format('embedded-opentype'),
    url(fonts/opensans/opensanshebrew-lightitalic-webfont.woff) format('woff'),
    url(fonts/opensans/opensanshebrew-lightitalic-webfont.ttf) format('truetype');
}
@font-face {
    font-family: 'Open Sans Hebrew';
    font-style: normal;
    font-weight: 300;
    src: url(fonts/opensans/opensanshebrew-light-webfont.eot);
    src: url(fonts/opensans/opensanshebrew-light-webfont.eot?#iefix) format('embedded-opentype'),
    url(fonts/opensans/opensanshebrew-light-webfont.woff) format('woff'),
    url(fonts/opensans/opensanshebrew-light-webfont.ttf) format('truetype');
}
@font-face {
    font-family: 'Open Sans Hebrew';
    font-style: italic;
    font-weight: 400;
    src: url(fonts/opensans/opensanshebrew-italic-webfont.eot);
    src: url(fonts/opensans/opensanshebrew-italic-webfont.eot?#iefix) format('embedded-opentype'),
    url(fonts/opensans/opensanshebrew-italic-webfont.woff) format('woff'),
    url(fonts/opensans/opensanshebrew-italic-webfont.ttf) format('truetype');
}
@font-face {
    font-family: 'Open Sans Hebrew';
    font-style: normal;
    font-weight: 400;
    src: url(fonts/opensans/opensanshebrew-regular-webfont.eot);
    src: url(fonts/opensans/opensanshebrew-regular-webfont.eot?#iefix) format('embedded-opentype'),
    url(fonts/opensans/opensanshebrew-regular-webfont.woff) format('woff'),
    url(fonts/opensans/opensanshebrew-regular-webfont.ttf) format('truetype');
}
@font-face {
    font-family: 'Open Sans Hebrew';
    font-style: italic;
    font-weight: 700;
    src: url(fonts/opensans/opensanshebrew-bolditalic-webfont.eot);
    src: url(fonts/opensans/opensanshebrew-bolditalic-webfont.eot?#iefix) format('embedded-opentype'),
    url(fonts/opensans/opensanshebrew-bolditalic-webfont.woff) format('woff'),
    url(fonts/opensans/opensanshebrew-bolditalic-webfont.ttf) format('truetype');
}
@font-face {
    font-family: 'Open Sans Hebrew';
    font-style: normal;
    font-weight: 700;
    src: url(fonts/opensans/opensanshebrew-bold-webfont.eot);
    src: url(fonts/opensans/opensanshebrew-bold-webfont.eot?#iefix) format('embedded-opentype'),
    url(fonts/opensans/opensanshebrew-bold-webfont.woff) format('woff'),
    url(fonts/opensans/opensanshebrew-bold-webfont.ttf) format('truetype');
}
@font-face {
    font-family: 'Open Sans Hebrew';
    font-style: italic;
    font-weight: 800;
    src: url(fonts/opensans/opensanshebrew-extrabold-webfont.eot);
    src: url(fonts/opensans/opensanshebrew-extrabold-webfont.eot?#iefix) format('embedded-opentype'),
    url(fonts/opensans/opensanshebrew-extrabold-webfont.woff) format('woff'),
    url(fonts/opensans/opensanshebrew-extrabold-webfont.ttf) format('truetype');
}
@font-face {
    font-family: 'Open Sans Hebrew';
    font-style: normal;
    font-weight: 800;
    src: url(fonts/opensans/opensanshebrew-extrabold-webfont.eot);
    src: url(fonts/opensans/opensanshebrew-extrabold-webfont.eot?#iefix) format('embedded-opentype'),
    url(fonts/opensans/opensanshebrew-extrabold-webfont.woff) format('woff'),
    url(fonts/opensans/opensanshebrew-extrabold-webfont.ttf) format('truetype');
}
