/* body.scrolling .header */

body.scrolling .header {
	&-desktop {
		top: 0;
		width: 100%;
	}

	&-main {
		background: transparentize($color-white,0.1);
		max-height: 50px;
		padding: 7px;
		position: $header-position;
		top: 0;

		&__logo {
			cursor: pointer;
			transform: scale(0.6,0.6);
			top: -30px;
			left: -50px;
			opacity: 0;
			margin-bottom: 250px;
			transition: all 0.5s ease;

			&_sm {
				opacity: 1;
				position: absolute;
				transition: all 0.5s ease;
				z-index: 3;
				display: inline-block;
				top: 0;
				float: $left;
				left: 50px;
				position: relative;
				width: 150px;

			}
			/* -main__logo_image_small  */
		}
		/* -main__logo_image  */
	}
}

.header-desktop {
	background: -webkit-linear-gradient(top, rgba(255,255,255,1) 45%, rgba(255,255,255,0.4) 30%, rgba(255,255,255,0) 100%);
	background: -o-linear-gradient(top, rgba(255,255,255,1) 45%, rgba(255,255,255,0.4) 30%, rgba(255,255,255,0) 100%);
	background: linear-gradient(to bottom, rgba(255,255,255,0.9) 5%, rgba(255,255,255,0.8) 30%, rgba(255,255,255,0.1) 90%, rgba(255,255,255,0) 100%);
	height: 140px;
	z-index: 4;
	position: $header-position;
	top: 0;
	width: 100%;
	box-shadow: none;
	@include respond-to('medium') {
		display: none;
	}
}

.header-main {
	z-index: 1;
	padding: 20px;
	position: $header-position;
	margin: 0 auto;
	width: 100%;

	&__menu {
		display: inline-block;
		position: relative;
		@include respond-to('huge') {
			margin: 0;
			padding: 0;
		}

		&_item{
			&:hover > a{
				background-color: transparentize($brand-color,0.05);
				color: $color-white;
			}
		}

		&_link {
			color: #000;
			font-weight: normal;
			max-height: 35px;
			padding: 8px 10px;
			border-radius: 5px;

			&.no-bottom-radius{
				border-bottom-left-radius: 0px;
				border-bottom-right-radius: 0px;
			}


			.selected & {
				background-color: transparentize($brand-color,0.05);
				color: $color-white;
			}
			@include respond-to('huge') {
				padding: 8px 1px;
			}
		}
		/*menu link*/
		&_item {
			display: inline-block;
			list-style-type: none;
			border-radius: 4px;
			color: $color-black;
			margin-left: 8px;
			font-size: 18px;
			z-index: 3;
			@include respond-to('huge') {
				margin: 0;
				font-size: 15px;
			} //---huge

			&_grand{
				position: relative;
				&:before{
					content: '';
					position: absolute;
					top: 15px;
					#{$left}: 6px;
					border: 5px solid #fff;
					border-top-color: transparent;
					border-right-color: transparent;
					transform: rotate(45deg);
				}
				ul{
					display: none;
					padding: 0;
					list-style-type: none;
					position: absolute;
					box-shadow: 0 0 10px rgba(0,0,0,.25);
					top: 50%;
					#{$right}: calc(100% - 25px);
					z-index: 10;

					li{
						white-space: nowrap;
						filter: brightness(1.1) !important;

						&:hover{
							filter: brightness(1) !important;
						}
					} //li
				} //ul
				&:hover{
					ul{
						display: block;
					}
				} //HOVER
			} //_grand

			&_parent{
				position: relative;
				&>ul{
					position: absolute;
					display: none;
					#{$right}: 0;
					background-color: transparent;
					list-style-type: none;
					padding: 0;
					border-radius: 5px 0 5px 5px;
					overflow: hidden;
					top: 30px;
					li{
						background-color: transparentize($brand-color,0.05);
						padding: 6px 25px 6px 15px;
						position: relative;
						white-space: nowrap;
						min-height: 20px;
						transition: .2s;
						border-bottom: 1px solid #1a69b9;
						border-top: 1px solid #054f9a;

						&:first-child{
							border-top:0;
						}

						&:last-child{
							border-bottom: 0;
						}

						&:after{
							content: '';
							width: 2px;
							position: absolute;
							#{$right}: 10px;
							top: 10px;
							bottom: 10px;
							background-color: #fff;
							border-radius: 0px;
						} //after
						a{
							color: #fff;
							font-weight: normal;
							display: block;
							width: 100%;
							height: 100%;
							font-size: 16px;
							font-weight: normal;

						} //a
						&:hover{
							filter: brightness(.9);
							z-index: 10;
							a{
								color: #fff;
							} //a
						} //HOVER
					} //li
				} //ul
				&:hover{
					&>ul{
						display: block;
					} //ul
				} //HOVER
			} //_parent
		} //_item
		/*menu item*/
		&_soc {
			a{

							@include respond-to('smallest') {
								font-size: 8px;
								white-space: nowrap;
								margin: 0 1px;
							}
			}
			position: relative;
			margin-left: 1px;
			display: inline;
			@include respond-to('large') {
				margin: 0;
			}
			@include respond-to('small') {
				margin: 0;
							float:left;
			}
			@include respond-to('extra-small') {


			}
			&:hover {
				i,
				img {
					@include respond-to('small') {
				transform: unset;
					}
					@include respond-to('extra-small') {
						transform: unset;

					}
				}
			}
			/*soc hover*/
			&_link {

				@include respond-to('large') {
					margin: 0;
					padding: 0;
				}

			}

			&_wz {
		max-width:40px;
				@include respond-to('large') {
					margin: 0;
					padding: 0;
				}
				@include respond-to('extra-small') {
					margin: 0;
					padding: 0;
									max-width:20px;
				}
			}
			/*wz*/
			&_yt {
				color: $youtube;
		max-width:35px;
		text-align: center;
				&-bg {
					color: $color-white;
					font-size: 1.6rem;
				}
				/*yt bg*/
				@include respond-to('large') {
					margin: 0;
					padding: 0;
				}
				@include respond-to('extra-small') {
					margin: 0;
					padding: 0;
									max-width:20px;
				}
			}
			/*yt*/
			&_fb {
						max-width:30px;
				color: $facebook;
				font-size: 2rem;
				@include respond-to('large') {
					margin: 0;
					padding: 0;
				}
				@include respond-to('small') {
					margin: 0;
					padding: 0;
				}
				@include respond-to('extra-small') {
					margin: 0;
					padding: 0;
									max-width:20px;
				}
			}
			/*fb*/
		}
		/*soc*/
		&_phone {
			&:hover &_icn {
				//transform: rotate(220deg);
				color: $color-white;
				@include respond-to('smallest') {
									font-size: 8px;
								}
			}

			&_icn {
				padding: 0 30px;
				//transform: rotate(260deg);
				font-size: 2rem;
				color: $brand-color;
				@include respond-to('large') {
					padding: 0;
				}
				@include respond-to('small') {
					font-size: 35px;
					padding: 0;
					position: absolute;
				}

				&_num {
					color: $color-black;
					font-size: 18px;
						white-space: nowrap;
					@include respond-to('small') {
    			 	font-size: 11px;
					}
					@include respond-to('extra-small') {
					 	font-size: 10px;
					}

				}
				/*num*/
			}
			/*icn*/
		}
		/*phone*/
	}
	/*menu*/
	&__logo {
		transition: all 0.5s ease;
		opacity: 1;
		position: absolute;
		top: 0;
		left: 33px;
		z-index: 10;
		transition: all 0.5s ease;

		&_link {
			display: inline-block;
			width: 100%;
			height: 100%;
			z-index: 100;

		}

		&:hover {
		}

		&_sm {
			opacity: 0;
			position: absolute;
			transition: all 0.5s ease;
			z-index: 10;
			display: inline-block;
			top: 0;
			float: $left;
			left: 50px;
			position: relative;

			display: none;

			&_link {
				display: inline-block;
				width: 100%;
				height: 100%;
				z-index: 222222222222228;
			}

			&:hover {
			}
		}
	}
}
/*main header*/
//Header for mobile devices
.header-mobile {
	position: fixed;
	display: none;
	height: 7rem;
	width: 100%;
	background: #fff;
	z-index: 5;
	@include respond-to('medium') {
		display: block;
		top: 0;
	}
	@include respond-to('small') {
		display: block;

	}
	@include respond-to('extra-small') {

	}
	@include respond-to('smallest') {
		display: block;
			height: 65px;
	}
	.hamburger-inner,
	.hamburger-inner::after,
	.hamburger-inner::before {
		background-color: $brand-color;
	}

	.header-mobile-logo {
		top: 8px;

		img {
			margin: 0 auto;
			top: 0;
			display: block;
			z-index: 2;
		}
		//img
	}
	//header-mobile-logo

	.phone-link{
		position: absolute;
		top: 8px;
		right: 75px;
	}

	.mobile-menu-btn {
		position: absolute;
		top: 0;
		//margin: 2rem;
		margin-top: 15px;
		margin-right: 10px;
		z-index: 100;

		&.is-active .hamburger-box {
 			transition: all 0.25s ease;
			z-index: 22222222;
			padding: 1px;
			border-radius: 60%;
			color:red;
			background: rgba(255, 255, 255, 1);


			@at-root .sb-slidebar {
				background-color: $brand-color;
				z-index: 2;


				nav {
					position: relative;
					z-index: 222222222222222222222222222222222222222222222222222222222;
					position: relative;
					top: 10vh;
					padding-right: 30px;
				}
			}
		}
	}
}
//header-mobile
